/**
 * 倒數重新載入Function
 * @param {loadFun} Function
 * @param {times} Number
 */
export const countdownReload = (loadFun, times) => {
  let count = times
  const timer = setInterval(() => {
    count--
    if (count === 0) {
      loadFun()
      count = times
    }
  }, 1000)
  return timer
}
